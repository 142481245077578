import { Link } from "gatsby"
import React from "react"

interface ButtonProps {
  text: string | JSX.Element
  to?: string
  href?: string
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  target?: string
  rel?: string
  submit?: boolean
  white?: boolean
  turquoise?: boolean
  pink?: boolean
  yellow?: boolean
  small?: boolean
  className?: string
  noShadow?: boolean
  noRadius?: boolean
}

const Button: React.FC<ButtonProps> = ({
  text,
  to,
  href,
  onClick,
  target,
  rel,
  submit,
  white,
  turquoise,
  pink,
  yellow,
  small,
  className,
  noShadow,
  noRadius,
}) => {
  const classes = `inline-flex items-center justify-center border border-transparent text-base font-bold  focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-yellow transition-colors duration-300 
  ${white && "bg-white border-gray-300 text-black hover:bg-gray-300"}
  ${turquoise && "bg-brand-turquoise-dark text-white hover:bg-brand-turquoise"}
  ${pink && "bg-brand-pink-dark text-white hover:bg-brand-pink"}
  ${yellow && "bg-brand-yellow-dark text-white hover:bg-brand-yellow"}
  ${
    !white &&
    !turquoise &&
    !yellow &&
    "bg-brand-pink-dark text-white hover:bg-brand-pink"
  }
  ${small ? "px-5 py-1" : "px-5 py-3"}
  ${noShadow ? "" : "shadow"}
  ${noRadius ? "" : "rounded-md"}
  ${className ? className : ""}`

  if (to) {
    return (
      <Link to={to} className={classes}>
        {text}
      </Link>
    )
  }

  if (href) {
    return (
      <a href={href} className={classes} target={target} rel={rel}>
        {text}
      </a>
    )
  }

  if (onClick) {
    return (
      <button onClick={onClick} className={classes}>
        {text}
      </button>
    )
  }

  if (submit) {
    return (
      <button type="submit" className={classes}>
        {text}
      </button>
    )
  }
  return (
    <button onClick={onClick} className={`bg-red-600 ${classes}`}>
      Missing props
    </button>
  )
}

export default Button
