import React, { ExoticComponent, Fragment, ReactNode } from "react"
import Label from "./Label"

interface RadioButtonProps {
  label?: string
  id?: string
  name: string
  value: string
  checked?: boolean
  required?: boolean
  white?: boolean
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  asFragment?: boolean
  noStyle?: boolean
  className?: string
  tooltip?: string
  disabled?: boolean
  groupLabel?: string
}

const RadioButton: React.FC<RadioButtonProps> = ({
  label,
  id,
  name,
  value,
  checked,
  required,
  white,
  onChange,
  asFragment,
  noStyle,
  className,
  tooltip,
  disabled,
  groupLabel,
}) => {
  let Tag: ExoticComponent<{ children?: ReactNode }> | string = "div"
  if (asFragment) {
    Tag = Fragment
  }

  return (
    <Tag>
      {groupLabel && (
        <Label
          white={white}
          id={`${name}-group`}
          label={groupLabel}
          required={required}
          tooltip={tooltip}
        />
      )}
      <div className="flex items-center">
        <input
          type="radio"
          id={id}
          name={name}
          value={value}
          checked={checked}
          disabled={disabled}
          onChange={onChange}
          required={required}
          className={`w-4 h-4 text-brand-yellow bg-gray-100 border-gray-300 focus:ring-2 
            ${white ? "focus:ring-white" : "focus:ring-brand-yellow"} 
            ${noStyle ? "" : "rounded-full shadow"} 
            ${disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}
            ${className || ""}`}
        />
        {label && (
          <label
            htmlFor={id}
            className={`ml-2 text-sm font-medium 
              ${white ? "text-white" : "text-gray-700"}
              ${disabled ? "opacity-50 cursor-not-allowed" : "cursor-pointer"}`}
          >
            {label}
          </label>
        )}
      </div>
    </Tag>
  )
}

export default RadioButton
