import axios from "axios"
import { navigate } from "gatsby"
import React, { useState } from "react"
import Button from "../common/Button"
import Input from "./elements/Input"
import Select from "./elements/Select"
import Wizard from "./elements/Wizard"
import H2 from "../typography/H2"
import P from "../typography/P"
import CheckboxCardControlled from "./elements/CheckboxCardControlled"
import Label from "./elements/Label"
import RadioButton from "./elements/RadioButton"
import {
  ClockIcon,
  GlobeAltIcon,
  LocationMarkerIcon,
  ShoppingBagIcon,
} from "@heroicons/react/outline"
import TextField from "./elements/Textfield"
import { type FormData } from "../../types/ChecklistFormData"

const initialFormData: FormData = {
  website: "",
  decisionTimeframe: "4weeks",
  decisionTimeframeOther: "",
  soleDecider: true,
  otherDeciders: "",
  businessType: "online",
  otherOffers: false,
  otherOffersFrom: "",
  canShareOffer: true,
  canShareOfferReason: "",
  systemAccess: "self",
  systemAccessPerson: "",
  system: "",
  systemOther: "",
  currentProvider: false,
  currentProviderName: "",
  regions: ["DE"],
  mainIncomeCurrentProposal: true,
  otherIncome: "",
  businessAge: "",
  businessAgeMonths: "",
  monthlyRevenue: "2k",
  averageCustomerValue: "",
  averageProductValue: "",
  customerSources: {
    online: "",
    store: "",
    onsite: "",
    referral: "",
  },
  keywords: [
    { keyword: "", position: "" },
    { keyword: "", position: "" },
    { keyword: "", position: "" },
    { keyword: "", position: "" },
    { keyword: "", position: "" },
  ],
  metrics: {
    ahrefs: "",
    seobility: "",
    sistrix: "",
  },
  marketingBudget: "250",
  marketingBudgetCustom: "",
  marketingChannels: {
    googleAds: "",
    metaAds: "",
    seo: "",
    phoneMarketing: "",
    emailMarketing: "",
  },
  googleMyBusiness: "",
  socialMedia: {
    facebook: "",
    instagram: "",
    tiktok: "",
  },
  hasSearchConsole: false,
  email: "",
  notes: "",
}

const svg = {
  example: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="inline"
      width={96}
      height={96}
      viewBox="0 0 24 24"
      strokeWidth={1}
      stroke="#000000"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
      <circle cx={12} cy={7} r={4} />
      <path d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2" />
    </svg>
  ),
}

export default function CheckListForm() {
  const [step, setStep] = useState(0)
  const [formData, setFormData] = useState<FormData>(initialFormData)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")

  const prevStep = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    setStep(prevCount => prevCount - 1)
  }

  const nexStep = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    const hasErrors = validateData(e)
    if (hasErrors) {
      return
    }
    setStep(prevCount => prevCount + 1)
  }

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target

    console.log(name, value)

    setFormData(prev => ({
      ...prev,
      [name]:
        value.toLowerCase() === "true"
          ? true
          : value.toLowerCase() === "false"
          ? false
          : value,
    }))
  }

  const handleNestedInputChange = (
    key: string,
    nestedKey: string,
    value: string
  ) => {
    setFormData(prev => ({
      ...prev,
      [key]: {
        ...prev[key],
        [nestedKey]: value,
      },
    }))
  }

  const handleCustomElementChange = (key: string, value: string) => {
    setFormData(prev => ({
      ...prev,
      [key]: value,
    }))
  }

  const handleCustomElementChangeArray = (key: string, value: string) => {
    setFormData(prev => {
      const index = prev[key].indexOf(value)
      if (index === -1) {
        return {
          ...prev,
          [key]: [...prev[key], value],
        }
      } else {
        return {
          ...prev,
          [key]: prev[key].filter((_, i) => i !== index),
        }
      }
    })
  }

  const handleKeywordChange = (index: number, key: string, value: string) => {
    setFormData(prev => ({
      ...prev,
      keywords: prev.keywords.map((keyword, i) =>
        i === index ? { ...keyword, [key]: value } : keyword
      ),
    }))
  }

  function isHidden(
    el: HTMLInputElement | HTMLTextAreaElement | HTMLSelectElement | HTMLElement
  ): boolean {
    let element = el
    while (element) {
      if (
        element.style.display === "none" ||
        element.style.visibility === "hidden" ||
        getComputedStyle(element).display === "none" ||
        getComputedStyle(element).visibility === "hidden" ||
        element.classList.contains("hidden")
      ) {
        return true
      }
      element = element.parentElement as HTMLElement
      if (!element) break
    }
    return false
  }

  const validateData = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ): boolean => {
    e.preventDefault()
    const inputs = document?.querySelectorAll("input:not([type='hidden'])")
    const textareas = document?.querySelectorAll("textarea")
    const selects = document?.querySelectorAll("select")

    const allElements = [...inputs, ...textareas, ...selects] as (
      | HTMLInputElement
      | HTMLTextAreaElement
      | HTMLSelectElement
    )[]

    let hasErrors = false

    allElements.forEach(element => {
      // Skip validation for hidden elements or their parent containers
      if (isHidden(element)) {
        return
      }

      // Only validate if the element is required and visible
      if (element.hasAttribute("required") && !element.checkValidity()) {
        element.reportValidity()
        hasErrors = true
      }
    })

    return hasErrors
  }

  const handleSubmit = async e => {
    e.preventDefault()
    setLoading(true)

    axios
      .post(`/.netlify/functions/send-checklist`, JSON.stringify(formData))
      .then(() => {
        navigate("/anfrage-gesendet/")
      })
      .catch(err => {
        setLoading(false)
        if (err?.response?.data && typeof err.response.data === "string") {
          setError(err.response.data)
        } else {
          setError(
            "Etwas ist schief gelaufen. Bitte melde Dich direkt bei uns per E-Mail: kontakt@seo-premium-agentur.de"
          )
        }
      })
  }

  return (
    <form name="Anfrage" method="POST" onSubmit={e => handleSubmit(e)}>
      <Wizard currentStep={step}>
        {/* Step 1: Basic Information */}
        <div id="step-0">
          <P className="mb-8">
            Willkommen! Heute werden wir nichts entscheiden können! Wir müssen
            erstmal alle Möglichkeiten und Sinn einer Optimierung prüfen.
          </P>

          <H2 className="mb-4">Grundlegende Informationen</H2>
          <P className="mb-8">
            Bitte geben Sie die grundlegenden Informationen zu Ihrer Website
            ein.
          </P>

          <Input
            label="Website URL"
            name="website"
            placeholder="www.ihre-website.de"
            value={formData.website}
            onChange={handleInputChange}
            required
          />

          <div className="mt-4">
            <Label label="Bis wann können Sie sich für eine Zusammenarbeit entscheiden?" />

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
              <CheckboxCardControlled
                label="Wochen"
                value={formData.decisionTimeframe === "4weeks"}
                onChange={() =>
                  handleCustomElementChange("decisionTimeframe", "4weeks")
                }
                svg={<Circle text="4" />}
              />
              <CheckboxCardControlled
                label="8 Wochen"
                value={formData.decisionTimeframe === "8weeks"}
                onChange={() =>
                  handleCustomElementChange("decisionTimeframe", "8weeks")
                }
                svg={<Circle text="8" />}
              />
              <CheckboxCardControlled
                label="Später"
                value={formData.decisionTimeframe === "later"}
                onChange={() =>
                  handleCustomElementChange("decisionTimeframe", "later")
                }
                svg={<Circle text={<ClockIcon className="w-8 h-8" />} />}
              />
            </div>
          </div>

          {formData.decisionTimeframe === "later" && (
            <div className="mt-4">
              <Input
                label="Grund für späteren Zeitpunkt"
                name="decisionTimeframeOther"
                type="text"
                value={formData.decisionTimeframeOther}
                onChange={handleInputChange}
                required={formData.decisionTimeframe === "later"}
              />
            </div>
          )}

          <div className="mt-4 space-y-4">
            <RadioButton
              groupLabel="Sind Sie der alleinige Entscheider?"
              id="soleDecider-yes"
              name="soleDecider"
              value="true"
              label="Ja"
              checked={formData.soleDecider}
              onChange={handleInputChange}
            />
            <RadioButton
              id="soleDecider-no"
              name="soleDecider"
              value="false"
              label="Nein"
              checked={!formData.soleDecider}
              onChange={handleInputChange}
            />
          </div>

          {!formData.soleDecider && (
            <div className="mt-4">
              <Input
                label="Wer sind die weiteren Entscheider?"
                name="otherDeciders"
                type="text"
                value={formData.otherDeciders}
                onChange={handleInputChange}
                required={!formData.soleDecider}
              />
            </div>
          )}

          <div className="mt-8">
            <Button
              onClick={e => {
                nexStep(e)
              }}
              text="Weiter"
              className="w-full"
            />
          </div>
        </div>

        {/* Step 2: Business Type */}
        <div id="step-1">
          <H2 className="mb-8">Ihre aktuelle Situation</H2>
          <div>
            <Label label="Art des Geschäfts" />

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
              <CheckboxCardControlled
                label="Online"
                value={formData.businessType === "online"}
                onChange={() =>
                  handleCustomElementChange("businessType", "online")
                }
                svg={<Circle text={<GlobeAltIcon className="w-8 h-8" />} />}
              />
              <CheckboxCardControlled
                label="Ladengeschäft"
                value={formData.businessType === "store"}
                onChange={() =>
                  handleCustomElementChange("businessType", "store")
                }
                svg={<Circle text={<ShoppingBagIcon className="w-8 h-8" />} />}
              />
              <CheckboxCardControlled
                label="Vor Ort beim Kunden"
                value={formData.businessType === "onsite"}
                onChange={() =>
                  handleCustomElementChange("businessType", "onsite")
                }
                svg={
                  <Circle text={<LocationMarkerIcon className="w-8 h-8" />} />
                }
              />
            </div>
            <div className="mt-4 space-y-4">
              <RadioButton
                groupLabel="Haben Sie bereits Angebote von anderen Dienstleistern?"
                id="otherOffers-yes"
                name="otherOffers"
                value="true"
                label="Ja"
                checked={formData.otherOffers}
                onChange={handleInputChange}
              />

              <RadioButton
                id="otherOffers-no"
                name="otherOffers"
                value="false"
                label="Nein"
                checked={!formData.otherOffers}
                onChange={handleInputChange}
              />

              {formData.otherOffers && (
                <>
                  <div className="mt-4">
                    <Input
                      label="Von wem?"
                      name="otherOffersFrom"
                      type="text"
                      value={formData.otherOffersFrom}
                      onChange={handleInputChange}
                      required={formData.otherOffers}
                    />
                    <P className="mt-2">
                      Wir bieten eine Bestpreis- und Seite 1 Garantie! Wenn wir
                      den Vertrag sehen, kann das Angebot verbessert werden.
                    </P>
                  </div>
                  <div className="mt-4 space-y-4">
                    <RadioButton
                      groupLabel="Wenn ja, können wir das Angebot anschauen?"
                      id="canShareOffer-yes"
                      name="canShareOffer"
                      value="true"
                      label="Ja"
                      checked={formData.canShareOffer}
                      onChange={handleInputChange}
                    />

                    <RadioButton
                      id="canShareOffer-no"
                      name="canShareOffer"
                      value="false"
                      label="Nein"
                      checked={!formData.canShareOffer}
                      onChange={handleInputChange}
                    />
                  </div>
                  {!formData.canShareOffer && (
                    <div className="mt-4">
                      <Input
                        label="Warum nicht?"
                        name="canShareOfferReason"
                        type="text"
                        value={formData.canShareOfferReason}
                        onChange={handleInputChange}
                        required={!formData.canShareOffer}
                      />
                    </div>
                  )}
                </>
              )}
            </div>
          </div>

          {/* System Access Section */}
          <div className="mt-8 space-y-6">
            <Label label="Wer hat Zugriff auf das System?" />
            <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
              <CheckboxCardControlled
                label="Selbst"
                value={formData.systemAccess === "self"}
                onChange={() =>
                  handleCustomElementChange("systemAccess", "self")
                }
                svg={undefined}
              />
              <CheckboxCardControlled
                label="Webdesigner"
                value={formData.systemAccess === "designer"}
                onChange={() =>
                  handleCustomElementChange("systemAccess", "designer")
                }
                svg={undefined}
              />
              <CheckboxCardControlled
                label="Mitarbeiter"
                value={formData.systemAccess === "employee"}
                onChange={() =>
                  handleCustomElementChange("systemAccess", "employee")
                }
                svg={undefined}
              />
            </div>

            {formData.systemAccess !== "self" && (
              <Input
                label="Name der zuständigen Person"
                name="systemAccessPerson"
                type="text"
                value={formData.systemAccessPerson}
                onChange={handleInputChange}
                required={formData.systemAccess !== "self"}
              />
            )}

            <div>
              <Label label="Welches System nutzen Sie?" />
              <Select
                options={[
                  { label: "Bitte wählen", value: "" },
                  { label: "WordPress", value: "wordpress" },
                  { label: "Shopify", value: "shopify" },
                  { label: "Wix", value: "wix" },
                  { label: "Squarespace", value: "squarespace" },
                  { label: "Joomla", value: "joomla" },
                  { label: "Drupal", value: "drupal" },
                  {
                    label: "Adobe Commerce (früher Magento)",
                    value: "magento",
                  },
                  { label: "TYPO3", value: "typo3" },
                  { label: "Blogger", value: "blogger" },
                  { label: "PrestaShop", value: "prestashop" },
                  { label: "Ionos", value: "ionos" },
                  { label: "Cantao", value: "cantao" },
                  { label: "Jimdo", value: "jimdo" },
                  { label: "Weebly", value: "weebly" },
                  { label: "Sonstiges", value: "other" },
                ]}
                onChange={e =>
                  handleCustomElementChange("system", e.target.value)
                }
              />
            </div>

            {formData.system === "other" && (
              <div className="mt-4">
                <Input
                  label="Welches andere System?"
                  name="systemOther"
                  type="text"
                  value={formData.systemOther}
                  onChange={handleInputChange}
                  required={formData.system === "other"}
                />
              </div>
            )}
          </div>

          <div className="mt-4 space-y-4">
            <RadioButton
              groupLabel="Betreut Sie aktuell ein Dienstleister in Sachen SEO oder Online-Marketing?"
              id="currentProvider-yes"
              name="currentProvider"
              value="true"
              label="Ja"
              checked={formData.currentProvider}
              onChange={handleInputChange}
            />

            <RadioButton
              id="currentProvider-no"
              name="currentProvider"
              value="false"
              label="Nein"
              checked={!formData.currentProvider}
              onChange={handleInputChange}
            />
          </div>

          {formData.currentProvider && (
            <div className="mt-4">
              <Input
                label="Wer betreut Sie aktuell in Sachen SEO oder Online-Marketing?"
                name="currentProviderName"
                type="text"
                placeholder="Name des aktuellen Dienstleisters"
                value={formData.currentProviderName}
                onChange={handleInputChange}
                required={formData.currentProvider}
              />
            </div>
          )}

          <div className="grid grid-cols-2 gap-3 mt-8">
            <Button
              onClick={e => prevStep(e)}
              text="Zurück"
              className="w-full"
              white
            />
            <Button
              onClick={e => nexStep(e)}
              text="Weiter"
              className="w-full"
            />
          </div>
        </div>

        {/* Step 3: Target Group and Reach */}
        <div id="step-2">
          <H2 className="mb-4">Zielgruppe und Reichweite</H2>
          <Label label="In welchen Regionen sind Sie aktiv?" />

          <div className="mt-8 space-y-6">
            <div className="grid grid-cols-2 gap-4 sm:grid-cols-4">
              <CheckboxCardControlled
                label="Deutschland"
                value={formData.regions.includes("DE")}
                onChange={() => handleCustomElementChangeArray("regions", "DE")}
                svg={undefined}
              />
              <CheckboxCardControlled
                label="Österreich"
                value={formData.regions.includes("AT")}
                onChange={() => handleCustomElementChangeArray("regions", "AT")}
                svg={undefined}
              />
              <CheckboxCardControlled
                label="Schweiz"
                value={formData.regions.includes("CH")}
                onChange={() => handleCustomElementChangeArray("regions", "CH")}
                svg={undefined}
              />
              <CheckboxCardControlled
                label="Weltweit"
                value={formData.regions.includes("GLOBAL")}
                onChange={() =>
                  handleCustomElementChangeArray("regions", "GLOBAL")
                }
                svg={undefined}
              />
            </div>

            <div className="mt-4 space-y-4">
              <RadioButton
                groupLabel="Wovon leben Sie aktuell – ist das aktuelle Unternehmen zudem sie diese Anfrage machen Ihr Haupterwerb?"
                id="mainIncomeCurrentProposal-yes"
                name="mainIncomeCurrentProposal"
                value="true"
                label="Ja"
                checked={formData.mainIncomeCurrentProposal}
                onChange={handleInputChange}
              />

              <RadioButton
                id="mainIncomeCurrentProposal-no"
                name="mainIncomeCurrentProposal"
                value="false"
                label="Nein"
                checked={!formData.mainIncomeCurrentProposal}
                onChange={handleInputChange}
              />
            </div>

            {!formData.mainIncomeCurrentProposal && (
              <div className="mt-4">
                <Input
                  label="Was ist Ihre Haupteinnahmequelle?"
                  name="otherIncome"
                  type="text"
                  placeholder="Name des Haupterwerbs"
                  value={formData.otherIncome}
                  onChange={handleInputChange}
                  required={!formData.mainIncomeCurrentProposal}
                />
              </div>
            )}

            <div className="grid grid-cols-2 gap-4">
              <Input
                label="Wie lange besteht Ihr Unternehmen? (Jahre)"
                name="businessAge"
                type="number"
                min="0"
                value={formData.businessAge}
                onChange={handleInputChange}
                required
              />
              <Input
                label="Monate"
                name="businessAgeMonths"
                type="number"
                min="0"
                max="11"
                value={formData.businessAgeMonths}
                onChange={handleInputChange}
                required
              />
            </div>

            <div>
              <Label label="Wie hoch ist Ihr aktueller monatlicher Umsatz?" />
              <div className="grid grid-cols-2 gap-4 sm:grid-cols-4">
                <CheckboxCardControlled
                  label="bis 2K"
                  value={formData.monthlyRevenue === "2k"}
                  onChange={() =>
                    handleCustomElementChange("monthlyRevenue", "2k")
                  }
                  svg={undefined}
                />
                <CheckboxCardControlled
                  label="bis 5K"
                  value={formData.monthlyRevenue === "5k"}
                  onChange={() =>
                    handleCustomElementChange("monthlyRevenue", "5k")
                  }
                  svg={undefined}
                />
                <CheckboxCardControlled
                  label="bis 10K"
                  value={formData.monthlyRevenue === "10k"}
                  onChange={() =>
                    handleCustomElementChange("monthlyRevenue", "10k")
                  }
                  svg={undefined}
                />
                <CheckboxCardControlled
                  label="über 10K"
                  value={formData.monthlyRevenue === "over10k"}
                  onChange={() =>
                    handleCustomElementChange("monthlyRevenue", "over10k")
                  }
                  svg={undefined}
                />
              </div>
            </div>

            <div className="grid grid-cols-1 gap-6 sm:grid-cols-2">
              <Input
                label="Durchschnittlicher Umsatz pro Kunde (€)"
                name="averageCustomerValue"
                type="number"
                min="0"
                value={formData.averageCustomerValue}
                onChange={handleInputChange}
                required
              />
              <Input
                label="Durchschnittlicher Produktwert (€)"
                name="averageProductValue"
                type="number"
                min="0"
                value={formData.averageProductValue}
                onChange={handleInputChange}
                required
              />
            </div>

            <div>
              <Label label="Woher kommen Ihre Kunden? (in %)" />
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
                <Input
                  label="Online"
                  name="customerSources.online"
                  type="number"
                  min="0"
                  max="100"
                  value={formData.customerSources.online}
                  onChange={e =>
                    handleNestedInputChange(
                      "customerSources",
                      "online",
                      e.target.value
                    )
                  }
                  required
                />
                <Input
                  label="Ladengeschäft"
                  name="customerSources.store"
                  type="number"
                  min="0"
                  max="100"
                  value={formData.customerSources.store}
                  onChange={e =>
                    handleNestedInputChange(
                      "customerSources",
                      "store",
                      e.target.value
                    )
                  }
                  required
                />
                <Input
                  label="Vor Ort"
                  name="customerSources.onsite"
                  type="number"
                  min="0"
                  max="100"
                  value={formData.customerSources.onsite}
                  onChange={e =>
                    handleNestedInputChange(
                      "customerSources",
                      "onsite",
                      e.target.value
                    )
                  }
                  required
                />
                <Input
                  label="Empfehlungen"
                  name="customerSources.referral"
                  type="number"
                  min="0"
                  max="100"
                  value={formData.customerSources.referral}
                  onChange={e =>
                    handleNestedInputChange(
                      "customerSources",
                      "referral",
                      e.target.value
                    )
                  }
                  required
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-3 mt-8">
            <Button
              onClick={e => prevStep(e)}
              text="Zurück"
              className="w-full"
              white
            />
            <Button
              onClick={e => nexStep(e)}
              text="Weiter"
              className="w-full"
            />
          </div>
        </div>

        {/* Step 4: SEO Goals and Keywords */}
        <div id="step-3">
          <H2>SEO-Ziele und Keywords</H2>

          <div className="mt-8 space-y-6">
            {formData.keywords.map((keyword, index) => (
              <div
                key={index}
                className="grid grid-cols-1 gap-4 sm:grid-cols-2"
              >
                <Input
                  label={`Keyword ${index + 1}`}
                  name={`keywords[${index}].keyword`}
                  type="text"
                  value={keyword.keyword}
                  onChange={e =>
                    handleKeywordChange(index, "keyword", e.target.value)
                  }
                  required={index === 0}
                />
                <Input
                  label="Aktuelle Position"
                  name={`keywords[${index}].position`}
                  type="number"
                  min="1"
                  value={keyword.position}
                  onChange={e =>
                    handleKeywordChange(index, "position", e.target.value)
                  }
                />
              </div>
            ))}

            <div className="space-y-4">
              <h3 className="text-lg font-medium text-gray-900">
                Kennen Sie Ihre Metriken / Kennzahlen?
              </h3>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                <Input
                  label="DR von Ahrefs"
                  name="metrics.ahrefs"
                  type="number"
                  min="0"
                  max="100"
                  value={formData.metrics.ahrefs}
                  onChange={e =>
                    handleNestedInputChange("metrics", "ahrefs", e.target.value)
                  }
                />
                <Input
                  label="SEO-Score von Seobility (%)"
                  name="metrics.seobility"
                  type="number"
                  min="0"
                  max="100"
                  value={formData.metrics.seobility}
                  onChange={e =>
                    handleNestedInputChange(
                      "metrics",
                      "seobility",
                      e.target.value
                    )
                  }
                />
                <Input
                  label="Sichtbarkeitsindex von Sistrix"
                  name="metrics.sistrix"
                  type="number"
                  min="0"
                  value={formData.metrics.sistrix}
                  onChange={e =>
                    handleNestedInputChange(
                      "metrics",
                      "sistrix",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>
          </div>

          <div className="grid grid-cols-2 gap-3 mt-8">
            <Button
              onClick={e => prevStep(e)}
              text="Zurück"
              className="w-full"
              white
            />
            <Button
              onClick={e => nexStep(e)}
              text="Weiter"
              className="w-full"
            />
          </div>
        </div>

        {/* Step 5: Marketing Budget */}
        <div id="step-4">
          <H2>Marketing-Budget und Maßnahmen</H2>

          <div className="mt-8 space-y-6">
            <div>
              <Label label="Wie viel geben Sie aktuell für Marketing aus?" />

              <div className="grid grid-cols-2 gap-4 sm:grid-cols-3">
                <CheckboxCardControlled
                  label="250,-- €"
                  value={formData.marketingBudget === "250"}
                  onChange={() =>
                    handleCustomElementChange("marketingBudget", "250")
                  }
                  svg={undefined}
                />
                <CheckboxCardControlled
                  label="500,-- €"
                  value={formData.marketingBudget === "500"}
                  onChange={() =>
                    handleCustomElementChange("marketingBudget", "500")
                  }
                  svg={undefined}
                />
                <CheckboxCardControlled
                  label="750,-- €"
                  value={formData.marketingBudget === "750"}
                  onChange={() =>
                    handleCustomElementChange("marketingBudget", "750")
                  }
                  svg={undefined}
                />
                <CheckboxCardControlled
                  label="1000,-- €"
                  value={formData.marketingBudget === "1000"}
                  onChange={() =>
                    handleCustomElementChange("marketingBudget", "1000")
                  }
                  svg={undefined}
                />
                <CheckboxCardControlled
                  label="1500,-- €"
                  value={formData.marketingBudget === "1500"}
                  onChange={() =>
                    handleCustomElementChange("marketingBudget", "1500")
                  }
                  svg={undefined}
                />
                <CheckboxCardControlled
                  label="Anderer Betrag"
                  value={formData.marketingBudget === "custom"}
                  onChange={() =>
                    handleCustomElementChange("marketingBudget", "custom")
                  }
                  svg={undefined}
                />
              </div>
            </div>

            {formData.marketingBudget === "custom" && (
              <Input
                label="Ihr Marketing-Budget (€)"
                name="marketingBudgetCustom"
                type="number"
                min="0"
                value={formData.marketingBudgetCustom}
                onChange={handleInputChange}
                required={formData.marketingBudget === "custom"}
              />
            )}

            <div className="grid grid-cols-1 gap-4 sm:grid-cols-2">
              <Input
                label="Google Ads Budget (€)"
                name="marketingChannels.googleAds"
                type="number"
                min="0"
                value={formData.marketingChannels.googleAds}
                onChange={e =>
                  handleNestedInputChange(
                    "marketingChannels",
                    "googleAds",
                    e.target.value
                  )
                }
              />
              <Input
                label="Meta Ads Budget (€)"
                name="marketingChannels.metaAds"
                type="number"
                min="0"
                value={formData.marketingChannels.metaAds}
                onChange={e =>
                  handleNestedInputChange(
                    "marketingChannels",
                    "metaAds",
                    e.target.value
                  )
                }
              />
              <Input
                label="SEO Budget (€)"
                name="marketingChannels.seo"
                type="number"
                min="0"
                value={formData.marketingChannels.seo}
                onChange={e =>
                  handleNestedInputChange(
                    "marketingChannels",
                    "seo",
                    e.target.value
                  )
                }
              />
              <Input
                label="Telefon-Marketing Budget (€)"
                name="marketingChannels.phoneMarketing"
                type="number"
                min="0"
                value={formData.marketingChannels.phoneMarketing}
                onChange={e =>
                  handleNestedInputChange(
                    "marketingChannels",
                    "phoneMarketing",
                    e.target.value
                  )
                }
              />
              <Input
                label="E-Mail-Marketing Budget (€)"
                name="marketingChannels.emailMarketing"
                type="number"
                min="0"
                value={formData.marketingChannels.emailMarketing}
                onChange={e =>
                  handleNestedInputChange(
                    "marketingChannels",
                    "emailMarketing",
                    e.target.value
                  )
                }
              />
            </div>
          </div>

          <div className="grid grid-cols-2 gap-3 mt-8">
            <Button
              onClick={e => prevStep(e)}
              text="Zurück"
              className="w-full"
              white
            />
            <Button
              onClick={e => nexStep(e)}
              text="Weiter"
              className="w-full"
            />
          </div>
        </div>

        {/* Step 6: Technical Details and Submission */}
        <div id="step-5">
          <H2>Technische Grundlagen</H2>
          <div className="mt-8 space-y-6">
            <Input
              label="Google My Business URL"
              name="googleMyBusiness"
              type="url"
              value={formData.googleMyBusiness}
              onChange={handleInputChange}
            />

            <div className="space-y-4">
              <h3 className="text-lg font-medium text-gray-900">
                Social Media Präsenz
              </h3>
              <div className="grid grid-cols-1 gap-4 sm:grid-cols-3">
                <Input
                  label="Facebook URL"
                  name="socialMedia.facebook"
                  type="url"
                  value={formData.socialMedia.facebook}
                  onChange={e =>
                    handleNestedInputChange(
                      "socialMedia",
                      "facebook",
                      e.target.value
                    )
                  }
                />
                <Input
                  label="Instagram URL"
                  name="socialMedia.instagram"
                  type="url"
                  value={formData.socialMedia.instagram}
                  onChange={e =>
                    handleNestedInputChange(
                      "socialMedia",
                      "instagram",
                      e.target.value
                    )
                  }
                />
                <Input
                  label="TikTok URL"
                  name="socialMedia.tiktok"
                  type="url"
                  value={formData.socialMedia.tiktok}
                  onChange={e =>
                    handleNestedInputChange(
                      "socialMedia",
                      "tiktok",
                      e.target.value
                    )
                  }
                />
              </div>
            </div>

            <div className="space-y-4">
              <RadioButton
                groupLabel="Ist die Google Search Console eingerichtet?"
                id="hasSearchConsole-yes"
                name="hasSearchConsole"
                value="true"
                label="Ja"
                checked={formData.hasSearchConsole}
                onChange={handleInputChange}
              />
              <RadioButton
                id="hasSearchConsole-no"
                name="hasSearchConsole"
                value="false"
                label="Nein"
                checked={!formData.hasSearchConsole}
                onChange={handleInputChange}
              />
            </div>

            <Input
              label="E-Mail"
              name="email"
              type="email"
              required
              value={formData.email}
              onChange={handleInputChange}
            />

            <TextField
              label="Weitere Notizen"
              name="notes"
              value={formData.notes}
              onChange={handleInputChange}
            />
            {error ? (
              <div className="p-3 my-3 bg-yellow-300">{error}</div>
            ) : null}
            <div className="grid grid-cols-2 gap-3 mt-8">
              <Button
                onClick={() => setStep(prev => prev - 1)}
                text="Zurück"
                className="w-full"
                white
              />
              {loading ? (
                <Button
                  onClick={() => null}
                  text={"lädt..."}
                  className="w-full cursor-wait"
                />
              ) : (
                <Button submit text={"Anfrage senden"} className="w-full" />
              )}
            </div>
          </div>
        </div>
      </Wizard>
    </form>
  )
}

function Circle({ text }: { text: string | JSX.Element }) {
  return (
    <div className="flex justify-center mb-4">
      <div className="flex items-center justify-center w-12 h-12 text-xl font-bold border-2 rounded-full border-cool-gray-900">
        {text}
      </div>
    </div>
  )
}
