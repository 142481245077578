import React, { ExoticComponent, Fragment, ReactNode } from "react"
import Label from "./Label"

interface TextFieldProps {
  label?: string
  id?: string
  name?: string
  placeholder?: string
  required?: boolean
  white?: boolean
  onChange?: (e: React.ChangeEvent<HTMLTextAreaElement>) => void
  value?: string
  asFragment?: boolean
  noStyle?: boolean
  className?: string
  tooltip?: string
  rows?: number
  maxLength?: number
  minLength?: number
  disabled?: boolean
  autoComplete?: string
  resize?: "none" | "vertical" | "horizontal" | "both"
}

const TextField: React.FC<TextFieldProps> = ({
  label,
  id,
  name,
  placeholder,
  required,
  white,
  onChange,
  value,
  asFragment,
  noStyle,
  className,
  tooltip,
  rows = 3,
  maxLength,
  minLength,
  disabled,
  autoComplete,
  resize = "vertical",
}) => {
  let Tag: ExoticComponent<{ children?: ReactNode }> | string = "div"
  if (asFragment) {
    Tag = Fragment
  }

  return (
    <Tag>
      {label && (
        <Label
          white={white}
          id={id}
          label={label}
          required={required}
          tooltip={tooltip}
        />
      )}
      <textarea
        className={`w-full p-4 leading-tight text-gray-700 appearance-none focus:outline-none ${
          white ? "focus:ring-white" : "focus:ring-brand-yellow"
        } ${noStyle ? "border-0" : "border rounded shadow"} ${
          className ? className : ""
        } ${
          resize === "none"
            ? "resize-none"
            : resize === "vertical"
            ? "resize-y"
            : resize === "horizontal"
            ? "resize-x"
            : "resize"
        }`}
        id={id}
        name={name}
        placeholder={placeholder}
        required={required}
        onChange={onChange}
        value={value}
        rows={rows}
        maxLength={maxLength}
        minLength={minLength}
        disabled={disabled}
        autoComplete={autoComplete}
      />
    </Tag>
  )
}

export default TextField
