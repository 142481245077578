import React, { ExoticComponent, Fragment, ReactNode } from "react"
import Label from "./Label"

interface Option {
  value: string
  label: string
}

interface SelectProps {
  label?: string
  id?: string
  name?: string
  placeholder?: string
  required?: boolean
  white?: boolean
  options: Option[]
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void
  value?: string
  asFragment?: boolean
  noStyle?: boolean
  className?: string
  tooltip?: string
  disabled?: boolean
}

const Select: React.FC<SelectProps> = ({
  label,
  id,
  name,
  placeholder,
  required,
  white,
  options,
  onChange,
  value,
  asFragment,
  noStyle,
  className,
  tooltip,
  disabled,
}) => {
  let Tag: ExoticComponent<{ children?: ReactNode }> | string = "div"
  if (asFragment) {
    Tag = Fragment
  }

  return (
    <Tag>
      {label && (
        <Label
          white={white}
          id={id}
          label={label}
          required={required}
          tooltip={tooltip}
        />
      )}
      <select
        className={`w-full p-4 leading-tight text-gray-700 appearance-none focus:outline-none ${
          white ? "focus:ring-white" : "focus:ring-brand-yellow"
        } ${noStyle ? "border-0" : "border rounded shadow"} ${
          className ? className : ""
        }`}
        id={id}
        name={name}
        required={required}
        onChange={onChange}
        value={value}
        disabled={disabled}
      >
        {placeholder && (
          <option value="" disabled>
            {placeholder}
          </option>
        )}
        {options.map(option => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </Tag>
  )
}

export default Select
