import { graphql, Link, useStaticQuery } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import SocialIcons from "../common/SociaIcons"
import SocialShare from "../common/SocialShare"

/* This example requires Tailwind CSS v2.0+ */
const navigation = {
  legal: [
    { name: "Impressum", href: "/impressum/" },
    { name: "Datenschutz", href: "/datenschutz/" },
    { name: "Newsletter", href: "/newsletter/" },
    { name: "Newsletter abmelden", href: "/newsletter/abmelden/" },
    { name: "Checkliste", href: "/checkliste/" },
  ],
}

export default function Footer({ grayFooter }) {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <footer
      className={`${grayFooter ? "bg-gray-100" : "bg-white"}`}
      aria-labelledby="footerHeading"
    >
      <h2 id="footerHeading" className="sr-only">
        Footer
      </h2>
      <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-16 lg:px-8">
        <div>
          <div className="text-center">
            <a
              title="Bewertungen und Erfahrungen zu seo-premium-agentur.de"
              href="https://www.webwiki.de/seo-premium-agentur.de"
              target="_blank"
              rel="noopener noreferrer"
            >
              <StaticImage
                src="https://www.webwiki.de/etc/rating/widget/1289515074/seo-premium-agentur-de-bewertung-round-220.png"
                alt="SEO Agentur Stuttgart Bewertungen zu seo-premium-agentur.de"
                title="SEO Agentur Stuttgart Bewertungen zu seo-premium-agentur.de"
                className="inline-block mb-5"
              />
            </a>
          </div>
          <SocialIcons />
          <SocialShare />
          <p className="text-base text-gray-400 xl:text-center">
            &copy; {new Date().getFullYear()} {data.site.siteMetadata.title}
          </p>
          <ul className="flex flex-row justify-center gap-3 mt-4">
            {navigation.legal.map((item, i) => (
              <li key={i}>
                <Link
                  to={item.href}
                  className="text-base text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-yellow"
                >
                  {item.name}
                </Link>
              </li>
            ))}

            <button
              type="button"
              data-cc="c-settings"
              className="text-base text-gray-500 hover:text-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-brand-yellow"
            >
              Cookie-Einstellungen
            </button>
          </ul>
        </div>
      </div>
    </footer>
  )
}
