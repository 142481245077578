import React from "react"

interface PProps {
  children: React.ReactNode
  className?: string
  textColor?: string
}

const P: React.FC<PProps> = ({ children, className, textColor }) => {
  return (
    <p
      className={`leading-relaxed ${
        textColor ? textColor : "text-gray-700"
      } ${className}`}
    >
      {children}
    </p>
  )
}

export default P
