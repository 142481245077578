import React from "react"
import Tooltip from "../../common/Tooltip"

interface LabelProps {
  label?: string
  id?: string
  required?: boolean
  white?: boolean
  tooltip?: string
}

const Label: React.FC<LabelProps> = ({
  label,
  id,
  required,
  white,
  tooltip,
}) => {
  return (
    <label
      className={`block mb-2 text-sm font-medium  ${
        white ? "text-white" : "text-black"
      }`}
      htmlFor={id}
    >
      {label}
      {required && "*"}
      {tooltip && <Tooltip text={tooltip} className="ml-2" />}
    </label>
  )
}

export default Label
