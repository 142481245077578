import React from "react"
import { CheckIcon } from "@heroicons/react/outline"

interface CheckboxCardControlledProps {
  value: boolean
  onChange: () => void
  label: string
  svg: React.ReactNode
}

const CheckboxCardControlled: React.FC<CheckboxCardControlledProps> = ({
  value,
  onChange,
  label,
  svg,
}) => {
  return (
    <div
      className="flex self-stretch w-full bg-white "
      onClick={onChange}
      onKeyUp={e => (e.key === "Enter" ? onChange() : undefined)}
      role="button"
      tabIndex={0}
    >
      <label
        className={`w-full shadow rounded text-center p-5 transition-colors duration-300 self-stretch cursor-pointer border border-transparent relative ${
          value ? "border-brand-pink-dark" : ""
        }`}
      >
        {value ? (
          <CheckIcon className="absolute w-8 h-8 p-2 text-white rounded-full bg-brand-pink-dark top-1 right-1" />
        ) : null}
        <div className="svg-checkbox-container">
          <div className="text-center">{svg}</div>
          <div>{label}</div>
        </div>
      </label>
    </div>
  )
}

export default CheckboxCardControlled
