import React from "react"
import Layout from "../components/layouts/Default"
import RequestLeftCol from "./../components/layouts/partials/RequestLeftCol"
import RequestRightCol from "./../components/layouts/partials/RequestRightCol"
import ChecklistForm from "../components/form/ChecklistForm"
import H1 from "../components/typography/H1"

export default function Page({ data, location }) {
  return (
    <Layout
      location={location}
      title="Anfragen SEO Agentur Stuttgart"
      desc=""
      noHeader
      grayFooter
    >
      <section>
        <div className="flex flex-wrap">
          <div className="w-full max-w-screen-lg p-8 lg:w-8/12 lg:order-2 lg:p-16">
            <RequestRightCol>
              <H1>Checkliste für eine Optimierung Ihrer Webseite </H1>
              <div className="mt-8">
                <ChecklistForm />
              </div>
            </RequestRightCol>
          </div>
          <div className="w-full p-8 bg-gray-100 lg:w-4/12 lg:order-1 lg:p-16 ">
            <RequestLeftCol />
          </div>
        </div>
      </section>
    </Layout>
  )
}
